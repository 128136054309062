<template>
  <div v-if="!item.hidden && item.children" class="menu-wrapper">
    <template
      v-if="
        hasOneShowingChild(item.children) &&
          !onlyOneChild.children &&
          !item.alwaysShow
      "
    >
      <a
        v-if="isExternalLink(onlyOneChild.path)"
        :href="onlyOneChild.path"
        target="blank"
      >
        apple
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <item
            v-if="onlyOneChild.meta"
            :icon="onlyOneChild.meta.icon"
            :title="onlyOneChild.meta.title"
          />
        </el-menu-item>
      </a>
      <router-link v-else :to="resolvePath(onlyOneChild.path)">
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <item
            v-if="onlyOneChild.meta"
            :icon="onlyOneChild.meta.icon"
            :title="onlyOneChild.meta.title"
          />
        </el-menu-item>
      </router-link>
    </template>

    <el-submenu v-else :index="item.name || item.path">
      <template slot="title">
        <item
          v-if="item.meta"
          :icon="item.meta.icon"
          :title="item.meta.title"
        />
      </template>

      <template v-for="child in item.children" v-if="!child.hidden">
        <sidebar-item
          v-if="child.children && child.children.length > 0"
          :is-nest="true"
          :item="child"
          :key="child.name"
          :base-path="resolvePath(child.path)"
          class="nest-menu"
        />

        <div v-else @click="to(child.path)" :key="child.name">
          <el-menu-item :index="resolvePath(child.path)">
            <item
              v-if="child.meta"
              :icon="child.meta.icon"
              :title="child.meta.title"
            />
          </el-menu-item>
        </div>
      </template>
    </el-submenu>
  </div>
</template>

<script>
import path from "path";
import { isValidURL } from "@/utils/validate";
import Item from "./Item";

export default {
  name: "SidebarItem",
  components: { Item },
  props: {
    // route配置json
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      onlyOneChild: null
    };
  },
  mounted() {},
  methods: {
    hasOneShowingChild(children) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false;
        } else {
          // temp set(will be used if only has one showing child )
          this.onlyOneChild = item;
          return true;
        }
      });
      if (showingChildren.length === 1) {
        return true;
      }
      return false;
    },
    resolvePath(routePath) {
      return path.resolve(this.basePath, routePath);
    },
    isExternalLink(routePath) {
      return isValidURL(routePath);
    },
    to(routePath) {
      const { path } = this.$route;
      if (routePath !== path) {
        return this.$router.push(this.resolvePath(routePath));
      }
      this.$router.replace({
        path: "/redirect" + path
      });
    }
  }
};
</script>
