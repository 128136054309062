import request from "@/utils/request";

/**
 * 登录【ok】
 * @param params
 * @returns {*}
 */
export function login(params) {
  return request({
    url: "/api/backend/login",
    method: "post",
    data: {
      ...params
    }
  });
}

/**
 * 刷新权限
 */
export function refreshPermMenu() {
  return request({
    url: "/api/backend/refreshPermMenu",
    method: "get",
    params: {}
  });
}

// export function logout() {
//   return request({
//     url: '/api/backend/user/logout',
//     method: 'post'
//   })
// }
