import Cookies from "js-cookie";

const TokenKey = "Art-Admin-Token";
const MenusKey = "Art-Admin-menus";
const MenusCheckKey = "Art-Admin-Menus-Check";
const UserKey = "Art-Admin-user";

export function setToken(token) {
  // 不保持登录，token在session周期内有效
  return sessionStorage.setItem(TokenKey, token);
}

export function getToken() {
  return sessionStorage.getItem(TokenKey) || Cookies.get(TokenKey);
}

export function setMenus(menus) {
  if (menus) {
    return sessionStorage.setItem(MenusKey, JSON.stringify(menus));
  }
}
export function getMenus() {
  const data = sessionStorage.getItem(MenusKey) || Cookies.get(MenusKey);
  let resultData = [];
  if (data) {
    resultData = JSON.parse(data);
  }
  return resultData;
}

export function setUser(user) {
  if (user) {
    return sessionStorage.setItem(UserKey, JSON.stringify(user));
  }
}

export function getUser() {
  const data = sessionStorage.getItem(UserKey) || Cookies.get(UserKey);
  let resultData = {};
  if (data) {
    resultData = JSON.parse(data);
  }
  return resultData;
}

/**
 * menus是否改变
 * @param bool
 */
export function setMenusCheckKey(bool) {
  return sessionStorage.setItem(MenusCheckKey, JSON.stringify(bool));
}

export function getMenusCheckKey() {
  return sessionStorage.getItem(MenusCheckKey) || false;
}

export function removeToken() {
  sessionStorage.removeItem(TokenKey);
  sessionStorage.removeItem(MenusKey);
  sessionStorage.removeItem(UserKey);
  sessionStorage.removeItem(MenusCheckKey);
  Cookies.remove(TokenKey);
  return;
}
