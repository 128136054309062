import { login } from "@/api/login";
import {
  getToken,
  setToken,
  removeToken,
  setMenus,
  setUser,
  setMenusCheckKey
} from "@/utils/auth";
import md5 from "js-md5";
import { Message } from "element-ui";
import { constantRouterMap } from "@/router";
import Layout from "@/views/layout/Layout.vue";

/**
 * 递归生成子菜单
 */
function createChildren(RouterMap, routerArr) {
  RouterMap.filter(item => {
    if (item.type === "MENU") {
      const itemRoute = {
        meta: { title: item.name, icon: item.icon.trim() },
        name: item.id + "" || "",
        path: (item.path && item.path.trim()) || "",
        component: () => import("@/views" + item.component_path)
      };
      if (item.children && item.children.length) {
        const childrenRouterArr = [];
        createChildren(item.children, childrenRouterArr);
        itemRoute.children = childrenRouterArr;
      }
      routerArr.push(itemRoute);
    }
  });
}

function filterAsyncRouter(asyncRouterMap) {
  const routerArr = [];
  asyncRouterMap.filter(route => {
    if (route.parent_id === 0) {
      let newRoute = {};
      // 多级
      if (route.children && route.children.length) {
        newRoute = {
          path: "",
          alwaysShow: true,
          component: Layout,
          meta: {
            icon: route.icon.trim(),
            title: route.name
          },
          name: route.name
        };
        const routerArr = [];
        createChildren(route.children, routerArr);
        newRoute.children = routerArr;
      } else {
        // 单级
        newRoute = {
          path: "",
          component: Layout,
          name: "",
          children: [
            {
              path: route.path,
              name: route.name,
              meta: { title: route.name, icon: route.icon },
              component: () => import("@/views" + route.component_path)
            }
          ]
        };
      }
      routerArr.push(newRoute);
    }
  });
  return routerArr;
}

const user = {
  state: {
    token: getToken(),
    username: "",
    avatar: "",
    roles: [],
    permissions: [],
    menus: [], // 菜单
    routers: [],
    addRouters: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, username) => {
      state.username = username;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus;
    },
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        const params = {
          account: userInfo.account.trim(),
          password: md5(userInfo.password),
          img_code: userInfo.img_code,
          img_key: userInfo.img_key
        };
        login(params)
          .then(response => {
            if (response.code === -2) {
              Message({
                message: response.msg,
                type: "error",
                duration: 3 * 1000
              });
              resolve(-2);
            } else {
              const data = response.data;
              setToken(data.token);
              commit("SET_TOKEN", data.token);
              commit("SET_MENUS", data.menus);
              setMenus(data.menus);
              setMenusCheckKey(true);
              const user = {
                id: data.id,
                name: data.name
              };
              setUser(user);
              resolve();
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GenerateRoutes({ commit }, data) {
      return new Promise(resolve => {
        const { accessedRouters } = data;
        const accessedRoutersSum = filterAsyncRouter(accessedRouters);
        commit("SET_ROUTERS", accessedRoutersSum);
        resolve();
      });
    },

    // 登出
    // LogOut({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     logout(state.token).then(() => {
    //       commit('SET_TOKEN', '')
    //       commit('SET_ROLES', [])
    //       removeToken()
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit("SET_TOKEN", "");
        commit("SET_MENUS", []);
        removeToken();
        resolve();
      });
    }
  }
};

export default user;
