import { asyncRouterMap, constantRouterMap } from "@/router";

/**
 * 通过meta.permissions判断是否与当前用户权限匹配
 * @param permissions
 * @param route
 */
function hasPermission(permissions, route) {
  if (route.meta && route.meta.permissions) {
    return permissions.some(
      permission => route.meta.permissions.indexOf(permission) >= 0
    );
  } else {
    return true;
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param permissions
 */
function filterAsyncRouter(asyncRouterMap, permissions) {
  const accessedRouters = asyncRouterMap.filter(route => {
    if (permissions.indexOf("all") >= 0 || hasPermission(permissions, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, permissions);
      }
      return true;
    }
    return false;
  });
  return accessedRouters;
}

function filterAsyncRouter2(asyncRouterMap, newAccessedRouters) {
  const accessedRouters = asyncRouterMap.filter(route => {
    console.log("route", route);
    const newRoute = {
      alwaysShow: true,
      component: () => import("@/views/user/index"),
      meta: {
        icon: route.icon.trim(),
        title: route.name
      },
      name: route.name,
      path: route.path
    };
    console.log("newRoute", newRoute);
    if (route.children && route.children.length) {
      newRoute.children = filterAsyncRouter2(
        route.children,
        newAccessedRouters
      );
    }
    newAccessedRouters.push(newRoute);
    return newAccessedRouters;
  });
}

const permissions = {
  state: {
    // routers: [],
    // addRouters: []
  },
  mutations: {
    // SET_ROUTERS: (state, routers) => {
    //   state.addRouters = routers;
    //   state.routers = constantRouterMap.concat(routers);
    // }
  },
  actions: {
    // GenerateRoutes({ commit }, data) {
    //   return new Promise(resolve => {
    //     const { accessedRouters } = data;
    //     console.log("accessedRouters", accessedRouters);
    //     // const { permissions } = data
    //     // let accessedRouters = filterAsyncRouter(asyncRouterMap, permissions)
    //     // const accessedRoutersSum = [];
    //     // filterAsyncRouter2(accessedRouters, accessedRoutersSum);
    //     commit("SET_ROUTERS", accessedRouters);
    //     // commit("SET_ROUTERS", accessedRouters);
    //     resolve();
    //   });
    // }
  }
};

export default permissions;
