import Vue from "vue";
import Router from "vue-router";
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router);

/* Layout */
import Layout from "../views/layout/Layout";

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//所有权限通用路由表
//如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true
  },
  {
    path: "/error/401",
    component: () => import("@/views/error/401"),
    hidden: true
  },
  {
    path: "/error/404",
    component: () => import("@/views/error/404"),
    hidden: true
  },
  {
    path: "",
    component: Layout,
    name: "",
    // hidden: true,
    children: [
      {
        path: "/",
        name: "Dashboard",
        meta: { title: "首页", icon: "dashboard", affix: true },
        component: () => import("@/views/dashboard/index")
      }
    ]
  },
  {
    path: "",
    component: Layout,
    name: "Info",
    hidden: true,
    children: [
      {
        path: "/info",
        component: () => import("@/views/info/index")
      }
    ]
  },
  // {
  //   path: "",
  //   component: Layout,
  //   name: "system22",
  //   children: [
  //     {
  //       path: "/system2",
  //       name: "system2",
  //       meta: { title: "系统管理", icon: "system" },
  //       component: Layout,
  //       children: [
  //         {
  //           path: "/system2/permission2",
  //           name: "permission2",
  //           meta: { title: "权限管理", icon: "system" },
  //           component: Layout,
  //           children: [
  //             {
  //               path: "/system2/permission2/menu2",
  //               name: "menu2",
  //               meta: { title: "菜单管理", icon: "system" },
  //               component: () =>
  //                 import("@/views/system/permissionSetting/menuManagement.vue")
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },

  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path*",
        component: () => import("@/views/redirect/index")
      }
    ]
  }
];

//实例化vue的时候只挂载constantRouter
export default new Router({
  mode: "history",
  routes: constantRouterMap
});

//异步挂载的路由
//动态需要根据权限加载的路由表
//meta无permissions字段则不做权限控制
export const asyncRouterMap = [
  { path: "*", redirect: "/error/404", hidden: true }
];
