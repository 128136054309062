import router from "./router";
import store from "./store";
import NProgress from "nprogress"; // Progress 进度条
import "nprogress/nprogress.css"; // Progress 进度条样式
import {
  getMenus,
  getToken,
  getMenusCheckKey,
  setMenusCheckKey
} from "@/utils/auth"; // 验权

let oneRun = true;
const whiteList = ["/login"]; // 不重定向白名单
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (getToken()) {
    if (to.path === "/login") {
      next({ path: "/login" });
      NProgress.done(); // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      const menus = getMenus();
      const isCheck = getMenusCheckKey();
      if (menus && menus.length !== 0) {
        if (oneRun || isCheck === "true") {
          setMenusCheckKey(false);
          oneRun = false; //必须在creatNewRouter() 执行
          // 拉取用户信息
          store
            .dispatch("GenerateRoutes", {
              accessedRouters: menus
            })
            .then(() => {
              // 根据permissions权限生成可访问的路由表
              router.addRoutes(store.getters.addRouters); // 动态添加可访问路由表
              next({ ...to, replace: true }); // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
            });
        } else {
          next();
        }
      } else {
        next({ path: "/login" });
        NProgress.done(); // if current page is dashboard will not trigger	afterEach hook, so manually handle it
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/login");
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done(); // 结束Progress
});
